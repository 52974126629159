import React from 'react';
import { Controls, layer, Layers, Map } from 'react-openlayers';
import ol from 'openlayers';

const OpenLayersMap = ({ coordinates }) => {  
  const cords = ol.proj.transform([Number(coordinates.long), Number(coordinates.lat)], 'EPSG:4326', 'EPSG:3857');
  const iconFeatures = new ol.Feature(new ol.geom.Point(cords));

  iconFeatures.setStyle(
    new ol.style.Style({
      image: new ol.style.Icon({ src: '/img/marker.png' }),
    }),
  );

  const source = new ol.source.Vector({ features: [iconFeatures] });
  cords[0] -= 0;

  // makes map work
  const updateMap = map => map && setTimeout(() => map.map.updateSize(), 200);

  return (
    <div className='openstreetmap-container'>
      <Map view={{ center: cords, zoom: 17, height: 800 }} ref={updateMap}>
        <Layers>
          <layer.Tile />
          <layer.Vector source={source} />
        </Layers>
        <Controls attribution={false} zoom={false} />
      </Map>
    </div>
  );
};

export default OpenLayersMap;