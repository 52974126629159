/* eslint-disable camelcase */
import * as React from 'react';
import 'antd/dist/antd.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { 
  UsersPage, 
  ProductsPage, 
  LoginPage, 
  UserDetailsPage, 
  ProductDetailsPage,
  AdminsListPage,
  VouchersPage 
} from './pages';

import BaseLayout from './components/layouts/Layout';

import PrivateRoute from './components/PrivateRoute';

import './assets/index.scss';

class App extends React.Component {
  state = {};

  render() {
    return (
      <Router basename="/">
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <BaseLayout>
            <PrivateRoute exact path="/" component={() => <Redirect to="/admins" />} />
            <PrivateRoute exact path="/admins" component={AdminsListPage} />
            <PrivateRoute exact path="/users" component={UsersPage} />
            <PrivateRoute exact path="/users/:id" component={UserDetailsPage} />
            <PrivateRoute exact path="/products" component={ProductsPage} />
            <PrivateRoute exact path="/products/:id" component={ProductDetailsPage} />
            <PrivateRoute exact path="/vouchers" component={VouchersPage} />
          </BaseLayout>
          <PrivateRoute path="*" component={AdminsListPage} />
        </Switch>
      </Router>
    );
  }
}

export default App;
