import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import baseConfig from '../../config';
import { refreshAccessToken, checkAccessToken } from './authorization';

const axiosInstance = axios.create({
  baseURL: baseConfig.API_URL,
  headers: { 'Accept-Language': 'ro' }
});

let refreshToken = null;
let accessToken = null;

/**
 * Interceptor for axios request.
 * @param {RequestConfig} config - the request config
 */
const requestInterceptor = config => {
  if (!config.headers.Authorization) {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
};

/**
 * Interceptor function for response error.
 * @param {object} error - the error generated by t he response.
 * @param {RequestConfig} error.config - the request config.
 * @param {object} error.response
 */
const responseErrorInterceptor = async error => {
  const originalRequest = error.config;
  refreshToken = localStorage.getItem('refresh_token');

  if (error.response.status === 401 && refreshToken) {
    const tokens = {
      access: localStorage.getItem('token'),
      refresh: localStorage.getItem('refresh_token')
    };

    let accessTokenIsValid = false;
    if (tokens.access) {
      try {
        accessTokenIsValid = await checkAccessToken(tokens.access);
      } catch (err) {
        accessTokenIsValid = false;
      }

      if (!accessTokenIsValid && tokens.refresh) {
        try {
          const access = await refreshAccessToken(tokens.refresh);
          const newTokens = { access, refresh: tokens.refresh };

          setTokens(newTokens);
          localStorage.setItem('token', access);
          originalRequest.headers.Authorization = `Bearer ${access}`;

          return axios(originalRequest);
        } catch (err) {
          localStorage.clear();
          return <Redirect to="/login" />;
        }
      }
    } else {
      localStorage.clear();
      return <Redirect to="/login" />;
    }
  }
  
  return Promise.reject(error);
};

/**
 * Sets access and refresh tokens.
 * @param {object} tokens - holds access and refresh tokens.
 * @param {string} tokens.access - access token to beused with requiest.
 * @param {string} tokens.refresh - refresh token used to refresh the access token.
 */
export const setTokens = tokens => {
  if (tokens) {
    accessToken = tokens.access;
    refreshToken = tokens.refresh;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    refreshToken = null;
    axiosInstance.defaults.headers.common.Authorization = null;
  }
};

export const getAccessToken = () => accessToken;

axiosInstance.interceptors.request.use(requestInterceptor);
axiosInstance.interceptors.response.use(res => res, responseErrorInterceptor);

export default axiosInstance;
