import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import VouchersTable from '../../components/tables/VouchersTable';
import { fetchVouchersList, patchVoucher } from '../../libs/api/vouchers';
import Search from '../../components/forms/SearchInput';

const vouchersPerPage = 50;

class VouchersPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      vouchers: [],
      total: null,
      page: 1,
      loading: false,
      error: null,
      query: null
    };

    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  componentDidMount() {
    this.getVouchersList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, query } = this.state;
    if(prevState.page !== page || prevState.query !== query) {
      this.getVouchersList(query)
    }
  }

  getVouchersList = searchValue => {
    const { page } = this.state;

    this.setState({ loading: true });
    fetchVouchersList({ page, perPage: vouchersPerPage, query: searchValue })
      .then(res => {
        this.setState({
          vouchers: res.data,
          total: res.total,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        if (error.response && error.response.data) {
          if (error.response.data.detail) {
            this.setState({ error: { message: error.response.data.detail } });
          } else if (
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors.length
          ) {
            this.setState({
              error: { message: error.response.data.non_field_errors[0] }
            });
          }
        }
      });
  };

  changePagination = (page, size) => {
    this.setState({ page });
  };

  handleSearch = value => {
    this.setState({ page: 1, query: value });
  };

  handleCheckbox(id) {
      const { vouchers } = this.state;

    const findedIndex = vouchers.findIndex(item => item.id === id);
    const upateVouchers = vouchers.map(item => item.id === id ? { ...item, active: !item.active } : { ...item, active: false})

    patchVoucher(id, { active: !vouchers[findedIndex].active })
      .then(() => {
        this.setState({ vouchers: upateVouchers })
        message.success("Active a fost modificat cu success");
      })
      .catch(error => {
        message.error(error.message);
      })
  }

  render() {
    const searchText = 'Search for voucher...';
    const { vouchers, loading, page, total, error } = this.state;

    const pagination = {
      page,
      perPage: vouchersPerPage,
      total,
      changePagination: this.changePagination
    };

    const newData = vouchers.map(voucher => ({ ...voucher, key: voucher.id }));

    return (
      <>
        {error && message.error(error.message)}
        <Search text={searchText} handleSearch={this.handleSearch} />
        <VouchersTable
          data={newData}
          loading={loading}
          pagination={pagination}
          handleCheckbox={this.handleCheckbox}
        />
      </>
    );
  }
}

export default withRouter(VouchersPage);
