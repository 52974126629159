import React, { Component } from 'react';
import { message, Button } from 'antd';
import AdminsTable from '../../components/tables/AdminsTable';
import EditAdminModal from '../../components/modals/EditAdminModal';
import { deleteAdmin, fetchAdminsList } from '../../libs/api/admins';

class AdminsListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      loading: false,
      error: null,
      isModalVisible: false,
      current: null
    };

    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
    this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
  }

  componentDidMount() {
    this.getAdminsList();
  }

  onDelete(id) {
    const { list } = this.state;

    deleteAdmin(id)
    .then(() => {
      message.success("Adminul a fost șters cu success!")
      this.setState({
        list: list.filter(item => item.id !== id)
      });
    })
    .catch(error => {
      message.error(error.message);
    })
  }

  onEdit(id) {
    const { list } = this.state;

    const findedAdmin = list.filter(item => item.id === id)[0];

    this.setState({
      current: findedAdmin,
      isModalVisible: true
    });
  }

  onAdd() {
    this.setState({ isModalVisible: true });
  }

  getAdminsList = () => {
    this.setState({ loading: true });
    fetchAdminsList()
      .then(res => {
        this.setState({
          list: res.data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        if (error.response && error.response.data) {
          if (error.response.data.detail) {
            this.setState({ error: { message: error.response.data.detail } });
          } else if (
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors.length
          ) {
            this.setState({
              error: { message: error.response.data.non_field_errors[0] }
            });
          }
        }
      });
  };

  closeModal() {
    this.setState({ 
      isModalVisible: false, 
      current: null 
    });
  }

  handleSubmitEdit(id, item) {
    const { list } = this.state;

    const newReference = [...list];
    const findedIndex = newReference.findIndex(data => data.id === id);
    newReference[findedIndex] = {...newReference[findedIndex], ...item};

    this.setState({
      isModalVisible: false,
      list: newReference,
      current: null
    });
  }

  handleSubmitAdd(item) {
    const { list } = this.state;

    this.setState({
      isModalVisible: false,
      list: [...list, item]
    });
  }

  render() {
    const {
      list,
      loading,
      error,
      isModalVisible,
      current
    } = this.state;

    let newData = [];

    if (list && list.length) {
      newData = list.map(user => ({ ...user, key: user.id }));
    }

    return (
      <div className="admins-list">
        {error && message.error(error.message)}
        <EditAdminModal
          visible={isModalVisible}
          handleSubmitAdd={this.handleSubmitAdd}
          handleSubmitEdit={this.handleSubmitEdit}
          data={current}
          onClose={this.closeModal}
        />
        <Button icon="plus" className="add" onClick={this.onAdd}>
          Adaugă
        </Button>
        <AdminsTable
          data={newData}
          loading={loading}
          pagination={false}
          handleDelete={this.onDelete}
          handleEdit={this.onEdit}
        />
      </div>
    );
  }
}

export default AdminsListPage;