import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import { format } from 'date-fns';
import Table from '../../components/tables/Table';
import { fetchUsers } from '../../libs/api/users';
import Search from '../../components/forms/SearchInput';

const usersPerPage = 50;

const columns = [
  {
    title: 'Username',
    dataIndex: 'username',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => text || '--'
  },
  {
    title: 'Nume',
    dataIndex: 'first_name',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => text || '--'
  },
  {
    title: 'Prenume',
    dataIndex: 'last_name',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => text || '--'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => text || '--'
  },
  {
    title: 'Ultima logare',
    dataIndex: 'last_login',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => (text ? format(text, 'MM-DD-YYYY') : '--')
  },
  {
    title: 'Data înregistrării',
    dataIndex: 'date_joined',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => (text ? format(text, 'MM-DD-YYYY') : '--')
  }
];

class UsersPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      users: [],
      total: null,
      page: 1,
      loading: false,
      error: null,
      query: null
    };
  }

  componentDidMount() {
    this.getUsersList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, query } = this.state;
    if(prevState.page !== page || prevState.query !== query) {
      this.getUsersList(query)
    }
  }

  getUsersList = searchValue => {
    const { page } = this.state;

    this.setState({ loading: true });
    fetchUsers({ page, perPage: usersPerPage, query: searchValue })
      .then(res => {
        this.setState({
          users: res.data,
          total: res.total,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        if (error.response && error.response.data) {
          if (error.response.data.detail) {
            this.setState({ error: { message: error.response.data.detail } });
          } else if (
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors.length
          ) {
            this.setState({
              error: { message: error.response.data.non_field_errors[0] }
            });
          }
        }
      });
  };

  changePagination = (page, size) => {
    this.setState({ page });
  };

  handleSearch = value => {
    this.setState({ page: 1, query: value });
  };

  handleRowClick = id => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push(`/users/${id}`)
  };

  render() {
    const searchText = 'Search for user...';
    const { users, loading, page, total, error } = this.state;

    const pagination = {
      page,
      perPage: usersPerPage,
      total,
      changePagination: this.changePagination
    };

    const newData = users.map(user => ({ ...user, key: user.id }));

    return (
      <>
        {error && message.error(error.message)}
        <Search text={searchText} handleSearch={this.handleSearch} />
        <Table
          data={newData}
          columns={columns}
          loading={loading}
          pagination={pagination}
          handleRowClick={this.handleRowClick}
        />
      </>
    );
  }
}

export default withRouter(UsersPage);
