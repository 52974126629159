import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';

const { Sider } = Layout;
const menuKeys = ['admins', 'users', 'products', 'vouchers'];

class LeftSider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: false
        }
    }

    onCollapse = collapsed => {
        this.setState({ collapsed });
    }

    getCurrentItem = () => {
        const { location } = this.props;
        const path = location.pathname;

        return menuKeys.filter(item => path.includes(item))[0];
    }

    render() {
        const { collapsed } = this.state;

        return (
            <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
            <div className="logo"><img src="/img/logo.png" alt="lensa" /></div>
            <Menu theme="dark" defaultSelectedKeys={this.getCurrentItem() || 'admins'} mode="inline">
              <Menu.Item key="admins">
                <Link to="/admins">
                  <Icon type="team" />
                  <span>Admins</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="users">
                <Link to="/users">
                  <Icon type="team" />
                  <span>Users</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="products">
                <Link to="/products">
                  <Icon type="shop" />
                  <span>Products</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="vouchers">
                <Link to="/vouchers">
                  <Icon type="gift" />
                  <span>Vouchers</span>
                </Link>
              </Menu.Item>
            </Menu>
          </Sider>
        )
    }
} 

export default withRouter(LeftSider);
