import React from 'react';
import { message } from 'antd';
import LoginForm from '../components/forms/LoginForm';
import { loginUser } from '../libs/api/authorization';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    };

  }

  submitForm = (credentials) => {
    const { history } = this.props;

    loginUser(credentials)
      .then(() => history.push('/users'))
      .catch(error => {
        if (error.response && error.response.data) {
          if (error.response.data.detail) {
            this.setState({ error: { message: error.response.data.detail } });
          } else if (error.response.data.non_field_errors && error.response.data.non_field_errors.length) {
            this.setState({ error: { message: error.response.data.non_field_errors[0] } });
          }
        }
      })
  }

  render() {
    const { error } = this.state;

    return (
      <div className="container login-page">
        {error && message.error(error.message)}
        <LoginForm onSubmit={this.submitForm} />
      </div>
    )
  }
}

export default LoginPage;
