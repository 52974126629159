import React from 'react';
import { withRouter } from 'react-router-dom';

import { Layout, Icon, Popconfirm } from 'antd';

const { Header } = Layout;

class LayoutHeader extends React.Component {
  state = {};

  getActivePage = () => {
    const { location } = this.props;
    const path = location.pathname.split('/')[1];

    return path.charAt(0).toUpperCase() + path.slice(1);
  };

  logout = () => {
    localStorage.clear();

    return this.props.history.push('/login');
  };

  render() {
    return (
      <Header>
        <span>{this.getActivePage()}</span>
        <div className="logout">
          <Popconfirm
            placement="leftTop"
            title="Are you sure you want to logout?"
            onConfirm={this.logout}
            okText="Yes"
            cancelText="No"
          >
            <Icon type="logout" />
          </Popconfirm>
        </div>
      </Header>
    );
  }
}

export default withRouter(LayoutHeader);
