import React from 'react';
import { message } from 'antd';
import { getProductById } from '../../libs/api/products';
import ProductDetails from '../../components/products/ProductDetails';

class ProductDetailsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      loading: false,
      error: null
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.fetchProductDetails(match.params.id);
    }
  }

  fetchProductDetails = id => {
    this.setState({ loading: true });

    getProductById(id)
      .then(product => this.setState({ product, loading: false }))
      .catch(error => {
        if (error.response && error.response.data) {
          if (error.response.data.detail) {
            this.setState({ error: { message: error.response.data.detail } });
          } else if (
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors.length
          ) {
            this.setState({
              error: { message: error.response.data.non_field_errors[0] }
            });
          }
        }
      });
  };

  render() {
    const { product, error } = this.state;
    if (!product) return null;
    return (
      <>
        {error && message.error(error.message)}
        <div className="product">
          <ProductDetails product={product} />
        </div>
      </>
    )
  }
}

export default ProductDetailsPage;
