import React from 'react';
import { Row, Col } from 'antd';
import { format } from 'date-fns';
import GoogleMapContainer from '../GoogleMapContainer';

const ProductDetails = ({ product }) => {
  const { user } = product;
  return (
    <Row type="flex" justify="center">
        <Col xs={24}>
            <Col xs={6} style={{ textAlign: 'center' }}>
                <img src={product.product.s_image} alt="product_image" />
            </Col>
            <Col xs={8}>
                <span className="head-title">Detalii utilizator</span>
                <Col xs={24} className="item">
                    <Col xs={12} className="title">
                        Username:
                    </Col>
                    <Col xs={12}>{user.username || '--'}</Col>
                </Col>
                <Col xs={24} className="item">
                    <Col xs={12} className="title">
                        Nume:
                    </Col>
                    <Col xs={12}>{user.first_name || '--'}</Col>
                </Col>
                <Col xs={24} className="item">
                    <Col xs={12} className="title">
                        Prenume:
                    </Col>
                    <Col xs={12}>{user.last_name || '--'}</Col>
                </Col>
                <Col xs={24} className="item">
                    <Col xs={12} className="title">
                        Email:
                    </Col>
                    <Col xs={12}>{user.email}</Col>
                </Col>
            </Col>
            <Col xs={10} style={{ paddingLeft: '25px' }}>
               <GoogleMapContainer coordinates={user.last_location || { lat: 0, long: 0 }} />
            </Col>
        </Col>
        <Col xs={24}>
            <Col xs={6} />
            <Col xs={18}>
                <span className="head-title">Detalii produs</span>
                <Col xs={24} className="item">
                    <Col xs={5} className="title">Denumirea: </Col>
                    <Col xs={18} className="value">
                        <a
                            href={product.product.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {product.product.title || '--'}
                        </a>
                    </Col>
                </Col>
                <Col xs={24} className="item">
                    <Col xs={5} className="title">Preț: </Col>
                    <Col xs={12} className="value">{product.product.price || '--' } Lei</Col>
                </Col>
                <Col xs={24} className="item">
                    <Col xs={5} className="title">Data și ora scanării: </Col>
                    <Col xs={12} className="value">{product.scanned_at ? format(product.scanned_at, 'MM-DD-YYYY, hh:mm:ss') : '--' }</Col>
                </Col>
                <Col xs={24} className="item">
                    <Col xs={5} className="title">Descriere: </Col>
                    <Col xs={16} className="value">
                        {product.product.short_description || '--'}
                    </Col>
                </Col>
            </Col>
        </Col>
    </Row>
  );
};

export default ProductDetails;
