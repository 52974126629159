import React from 'react';
import { Input } from 'antd';

const { Search } = Input;

class SearchInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { handleSearch, text } = this.props;

    return (
      <Search
        placeholder={text}
        onSearch={value => handleSearch(value)}
        style={{ width: 200, marginBottom: '10px' }}
      />
    );
  }
}

export default SearchInput;
