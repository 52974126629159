import React from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { createAdmin, patchAdmin } from '../../libs/api/admins';

const MIN_PWD_LENGTH = 6;
const passMinLengthError = `Password should be at least ${MIN_PWD_LENGTH} characters long`;

class EditAdminModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
  }

  setFieldsErrors(errors) {
    const fields = this.props.form.getFieldsValue();
    const newFields = {};

    Object.keys(fields).forEach(key => {
      if (errors[key]) {
        newFields[key] = {
          value: fields[key],
          errors: [new Error(errors[key])]
        };
      }
    });

    this.props.form.setFields(newFields);
  }

  handleSubmit(e) {
    const { 
        form, 
        data, 
        handleSubmitEdit,
        handleSubmitAdd 
    } = this.props;

    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
          if (data){
            patchAdmin(data.id, values)
              .then(admin => {
                message.success("Adminul a fost editat cu success")
                handleSubmitEdit(data.id, admin)
              })
              .catch(error => {
                if (error.response && error.response.data) {
                  this.setFieldsErrors(error.response.data)
                } else {
                  message.error(error.message);
                }  
              })
            
          } else {
            createAdmin(values)
            .then(admin => {
              message.success("Adminul a fost creat cu success")
              handleSubmitAdd(admin)
            })
            .catch(error => {
              if (error.response && error.response.data) {
                this.setFieldsErrors(error.response.data)
              } else {
                message.error(error.message);
              }  
            })
          }
      }
    });
  }

  handleCancel() {
    this.props.onClose()
  }

  compareToFirstPassword(rule, value, callback) {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback("Passwords don't match");
    } else {
      callback();
    }
  }

  render() {
    const { data, visible, form, onClose } = this.props;
    const { getFieldDecorator } = form;
    const title = data ? 'Editează' : 'Adaugă';

    const passwordRules = [
      { required: !data ? true : false, message: "Password is required" },
      { min: MIN_PWD_LENGTH, message: passMinLengthError }
    ];
    const passwordRepeatRules = [
      { required: !data ? true : false, message: "Password is required" },
      { validator: this.compareToFirstPassword }
    ];

    return (
      <Modal
        title={title}
        className="admins"
        visible={visible}
        onCancel={onClose}
        destroyOnClose={true}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Username">
            {getFieldDecorator('username', {
              rules: [
                {
                  required: true,
                  message: 'Please input username!'
                }
              ],
              initialValue: data ? data.username : null
            })(<Input />)}
          </Form.Item>
          <Form.Item label="First name">
            {getFieldDecorator('first_name', {
              rules: [
                {
                  required: true,
                  message: 'Please input first name!'
                },
              ],
              initialValue: data ? data.first_name : null
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Last name">
            {getFieldDecorator('last_name', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm last name!'
                }
              ],
              initialValue: data ? data.last_name : null
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm email!'
                }
              ],
              initialValue: data ? data.email : null
            })(<Input type="email" />)}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator('password', {
              rules: passwordRules,
              initialValue: data ? data.password : null
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Repeat password">
            {getFieldDecorator('confirm_password', {
              rules: passwordRepeatRules,
              initialValue: data ? data.confirm_password : null
            })(<Input />)}
          </Form.Item>
          <div className="footer">
            <Button type="info" className="cancel" onClick={this.handleCancel}>Anuleaza</Button>
            <Button type="info" className="success" htmlType="submit">Salveaza</Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(EditAdminModal);
