const env = process.env.BUILD_ENV || "development";

const configs = {
    development: {
        API_URL: "https://app.lensa.ro/api/v1/lensa/"
    },
    testing: {
        API_URL: "https://app.lensa.ro/api/v1/lensa/"
    },
    production: {
        API_URL: "https://app.lensa.ro/api/v1/lensa/"
    }
}[env];

module.exports = configs;
