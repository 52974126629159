import axios from './axios';

// eslint-disable-next-line import/prefer-default-export
export const fetchVouchersList = async params => {
  try {
    const res = await axios.get('/vouchers/', { params });

    return res.data;
  } catch (e) {
    throw e;
  }
};

export const patchVoucher = async (id, body) => {
    try {
        const res = await axios.patch(`/vouchers/${id}`, body);
        
        return res.data;
    } catch (e) {
        throw e;
    }
}