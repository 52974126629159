import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Avatar } from 'antd';
import { format } from 'date-fns';
import Table from '../../components/tables/Table';
import { fetchProducts } from '../../libs/api/products';
import Search from '../../components/forms/SearchInput';

const productsPerPage = 50;

const columns = [
  {
    title: 'Imagine',
    dataIndex: 'image',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => (text ? <Avatar size="large" src={text} /> : '--')
  },
  {
    title: 'Data și ora scanării',
    dataIndex: 'scannedDate',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => (text ? format(text, 'MM-DD-YYYY, hh:mm:ss') : '--')
  },
  {
    title: 'Nume',
    dataIndex: 'firstName',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => text || '--'
  },
  {
    title: 'Prenume',
    dataIndex: 'lastName',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => text || '--'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => text || '--'
  },
  {
    title: 'Denumirea',
    dataIndex: 'name',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => text || '--'
  },
  {
    title: 'Promoția',
    dataIndex: 'isActive',
    // eslint-disable-next-line no-unused-vars
    render: (text, item) => (
      text ? 
      <img src="/img/check.svg" alt="active" style={{ width: 20, height: 20 }} />  
      : '--'
    )
  }
];

class ProductsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      total: null,
      page: 1,
      loading: false,
      error: null,
      query: null
    };
  }

  componentDidMount() {
    this.getProductsList()
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, query } = this.state;
    if(prevState.page !== page || prevState.query !== query) {
      this.getProductsList(query)
    }
  }

  getProductsList = searchValue => {
    const { page } = this.state;
    this.setState({ loading: true });

    fetchProducts({ page, perPage: productsPerPage, query: searchValue })
      .then(res => {
        this.setState({
          products: res.data,
          total: res.total,
          loading: false 
        }); 
      })
      .catch(error => {
        this.setState({ loading: false })
        if (error.response && error.response.data) {
          if (error.response.data.detail) {
            this.setState({ error: { message: error.response.data.detail } });
          } else if (error.response.data.non_field_errors && error.response.data.non_field_errors.length) {
            this.setState({ error: { message: error.response.data.non_field_errors[0] } });
          }
        }
      })
  }

  changePagination = (page, size) => {
    this.setState({ page })
  };

  handleSearch = value => {
    this.setState({ page: 1, query: value });
  }

  getProductDetails = id => {
        // eslint-disable-next-line react/destructuring-assignment
        this.props.history.push(`/products/${id}`)
  }

  render() {
    const searchText = 'Search for product...'
    const { 
      products,
      loading, 
      page, 
      total, 
      error 
    } = this.state;

    const pagination = {
      page,
      perPage: productsPerPage,
      total,
      changePagination: this.changePagination
    }

    let newData = [];
    if (products && products.length){
      newData = products.map(item => ({
        key: item.id,
        scannedDate: item.scanned_at,
        firstName: item.user && item.user.first_name,
        lastName: item.user && item.user.last_name,
        email: item.user && item.user.email,
        name: item.product && item.product.title,
        isActive: item.product && item.product.active,
        image: item.product && item.product.s_image
      }));
    }

    return (
      <>
        {error && message.error(error.message)}
        <Search 
          text={searchText} 
          handleSearch={this.handleSearch} 
        />
        <Table
          data={newData}
          columns={columns}
          loading={loading}
          pagination={pagination}
          handleRowClick={this.getProductDetails}
        />
      </>
    );
  }
}

export default withRouter(ProductsPage);
