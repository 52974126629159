import React from 'react';
import { Row, Col } from 'antd';
import GoogleMapContainer from '../GoogleMapContainer';

const UserDetails = ({ user }) => {
  return (
    <Row type="flex" style={{ width: '100%' }}>
      <Col xs={8}>
        <span className="head-title">Detalii utilizator</span>
        <Col xs={24} className="item">
            <Col xs={8} className="title">Username:</Col> 
            <Col xs={12}>{user.username || '--'}</Col>
        </Col>
        <Col xs={24} className="item">
          <Col xs={8} className="title">Nume:</Col> 
          <Col xs={12}>{user.first_name || '--'}</Col>
        </Col>
        <Col xs={24} className="item">
          <Col xs={8} className="title">Prenume:</Col> 
          <Col xs={12}>{user.last_name || '--' }</Col>
        </Col>
        <Col xs={24} className="item">
          <Col xs={8} className="title">Email:</Col> 
          <Col xs={12}>{user.email || '--' }</Col>
        </Col>
      </Col>
      <Col xs={12} style={{ textAlign: 'center', marginLeft: '20px' }}>
         <GoogleMapContainer coordinates={user.last_location || { lat: 0, long: 0 }} />
      </Col>
    </Row>
  );
};

export default UserDetails;
