import axios from './axios';

// eslint-disable-next-line import/prefer-default-export
export const fetchProducts = async params => {
  try {
    const res = await axios.get('/products/scanned/', { params });

    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getProductById = async id => {
  try {
    const res = await axios.get(`/products/scanned/${id}`);

    return res.data;
  } catch (e) {
    throw e;
  }
}
