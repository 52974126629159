import React from 'react';
import { Layout } from 'antd';

import LeftSider from './Sider';
import Header from './Header';

const { Content, Footer } = Layout;

class MainLayout extends React.Component {
  state = {};

  render() {
    const { children } = this.props;

    return (
      <Layout>
        <LeftSider />
        <Layout>
          <Header />
          <Content>
            <div className="container" style={{ margin: '16px 0' }}>{children}</div>
          </Content>
          <Footer>Lensa ©2019 Created by <a href="https://ebs-integrator.com/" target="_blank" rel="noopener noreferrer">EBS Integrator</a></Footer>
        </Layout>
      </Layout>
    );
  }
}

export default MainLayout;
