import axios from './axios';

// eslint-disable-next-line import/prefer-default-export
export const fetchAdminsList = async params => {
  try {
    const res = await axios.get('/admins/', { params });

    return res.data;
  } catch (e) {
    throw e;
  }
};

export const createAdmin = async body => {
    try {
        const res = await axios.post('/admins/register/', body);
        
        return res.data;
    } catch (e) {
        throw e;
    }
}

export const patchAdmin = async (id, body) => {
    try {
        const res = await axios.patch(`/admins/${id}`, body);
        
        return res.data;
    } catch (e) {
        throw e;
    }
}

export const deleteAdmin = async id => {
    try {
        const res = await axios.delete(`/admins/${id}`);

        return res.data;
    } catch (e) {
        throw e;
    }
}