import fetch from 'cross-fetch';
import axios, { setTokens } from './axios';
import baseConfig from '../../config';

// eslint-disable-next-line import/prefer-default-export
export const loginUser = async credentials => {
  try {
    const res = await axios.post('/authorization/token/', credentials);
    await setTokensInStorage(res.data);

    setTokens(res.data);
  } catch (e) {
    throw e;
  }
};

export const refreshAccessToken = async refresh => {
  const res = await fetch(`${baseConfig.API_URL}authorization/token/refresh/`, {
    method: 'POST',
    body: JSON.stringify({ refresh }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  
  const data = await res.json();

  if (res.ok) {
    return data.access;
  }

  throw data;
};

export const checkAccessToken = async token => {
  if (typeof token !== "string") {
    throw new Error("Provided access token is not a string");
  }

  try {
    const endpoint = `${baseConfig.API_URL}authorization/token/verify/`;
    const options = {
      method: 'POST',
      body: JSON.stringify({ token }),
      headers: {
        "Content-Type": "application/json"
      },
    };
    const res = await fetch(endpoint, options);
    return res.ok;
  } catch (e) {
    throw e;
  }
}

const setTokensInStorage = async tokens => {
  try {
    localStorage.setItem('token', tokens.access);
    localStorage.setItem('refresh_token', tokens.refresh);
  } catch (e) {
    throw e;
  }
}
