import React from 'react';
import { Table, Pagination } from 'antd';

const CustomTable = ({ data, columns, pagination, loading, handleRowClick }) => (
  <div className="table-container">
    <Table
      columns={columns}
      dataSource={data}
      size="middle"
      loading={loading}
      pagination={false}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => handleRowClick(record.key)
        };
      }}
    />
    <Pagination
      current={pagination.page}
      total={pagination.total}
      pageSize={pagination.perPage}
      onChange={pagination.changePagination}
    />
  </div>
);

export default CustomTable;
