import React from 'react';
import { Form, Button, Input } from 'antd';
import { Link } from 'react-router-dom';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    handleSubmit = (e) => {
        const { form, onSubmit } = this.props;

        e.preventDefault();
        form.validateFields((err, values) => {
          if (!err) {
            onSubmit(values);
          }
        });
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <div className="title">Sign in to continue</div>
                <Form.Item
                  label="Email"
                >
                {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email!' }],
                })(
                    <Input type="text" placeholder="Email" />
                )}
                </Form.Item>
                <Form.Item
                  label="Password"
                >
                {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                    <Input type="password" placeholder="Password" />
                )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Log in
                    </Button>
                    <div className="bottom-links">
                        <Link to="/">Go Back</Link>
                        <Link to="/">
                            <span className="login-form-forgot">Forgot password ?</span>
                        </Link>
                    </div>
                </Form.Item>
            </Form>
        )
    }
}

const WrappedLoginForm = Form.create()(LoginForm);

export default WrappedLoginForm;