import React from 'react';
import { Table, Pagination, Checkbox } from 'antd';
import { format } from 'date-fns';

class VouchersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.columns = [
      {
        title: 'Code',
        dataIndex: 'code',
        // eslint-disable-next-line no-unused-vars
        render: (text, item) => text || '--'
      },
      {
        title: 'Value',
        dataIndex: 'value',
        // eslint-disable-next-line no-unused-vars
        render: (text, item) => text || '--'
      },
      {
        title: 'Data creării',
        dataIndex: 'date_created',
        // eslint-disable-next-line no-unused-vars
        render: (text, item) => (text ? format(text, 'MM-DD-YYYY') : '--')
      },
      {
        title: 'Active',
        dataIndex: 'active',
        // eslint-disable-next-line no-unused-vars
        render: (text, item) => ( <Checkbox checked={text} onChange={() => this.handleCheckbox(item.id)} /> )
      }
    ];
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleCheckbox(id) {
    this.props.handleCheckbox(id);
  }

  render() {
    const { data, pagination, loading } = this.props;
    return (
      <div className="table-container">
        <Table
          columns={this.columns}
          dataSource={data}
          size="middle"
          loading={loading}
          pagination={false}
        />
        {pagination && (
          <Pagination
            current={pagination.page}
            total={pagination.total}
            pageSize={pagination.perPage}
            onChange={pagination.changePagination}
          />
        )}
      </div>
    );
  }
}

export default VouchersTable;
