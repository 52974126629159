import React from 'react';
import { Table, Pagination, Popconfirm, Button } from 'antd';
import { format } from 'date-fns';

class AdminsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.columns = [
      {
        title: 'Username',
        dataIndex: 'username',
        // eslint-disable-next-line no-unused-vars
        render: (text, item) => text || '--'
      },
      {
        title: 'Nume',
        dataIndex: 'first_name',
        // eslint-disable-next-line no-unused-vars
        render: (text, item) => text || '--'
      },
      {
        title: 'Prenume',
        dataIndex: 'last_name',
        // eslint-disable-next-line no-unused-vars
        render: (text, item) => text || '--'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        // eslint-disable-next-line no-unused-vars
        render: (text, item) => text || '--'
      },
      {
        title: 'Ultima logare',
        dataIndex: 'last_login',
        // eslint-disable-next-line no-unused-vars
        render: (text, item) => (text ? format(text, 'MM-DD-YYYY') : '--')
      },
      {
        title: 'Data înregistrării',
        dataIndex: 'date_joined',
        // eslint-disable-next-line no-unused-vars
        render: (text, item) => (text ? format(text, 'MM-DD-YYYY') : '--')
      },
      {
        title: 'Actions',
        render: (text, item) => {
          return (
            <div className="actions">
              <Button
                icon="edit"
                className="btn-edit"
                onClick={() => this.handleEdit(item && item.id)}
              >
                Editează
              </Button>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => this.handleDelete(item && item.id)}
              >
                <Button icon="delete" className="btn-delete">Șterge</Button>
              </Popconfirm>
            </div>
          );
        }
      }
    ];
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete(id) {
    this.props.handleDelete(id);
  }

  handleEdit(id) {
    this.props.handleEdit(id);
  }

  render() {
    const { data, pagination, loading } = this.props;

    return (
      <div className="table-container">
        <Table
          columns={this.columns}
          dataSource={data}
          size="middle"
          loading={loading}
          pagination={false}
        />
        {
          pagination && (
            <Pagination
              current={pagination.page}
              total={pagination.total}
              pageSize={pagination.perPage}
              onChange={pagination.changePagination}
            />
          )
        }
      </div>
    );
  }
}

export default AdminsTable;