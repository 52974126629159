import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  isUserLogged = () => {
    return localStorage.getItem('token');
  }

  render() {
    const { component, ...rest } = this.props;
    if (this.isUserLogged()) {
      return <Route {...rest} component={component} />;
    }

    return <Redirect to="/login" />;
  }
}

export default PrivateRoute;
