import axios from './axios';

// eslint-disable-next-line import/prefer-default-export
export const fetchUsers = async params => {
  try {
    const res = await axios.get('/users/', { params });

    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getUserById = async id => {
  try {
    const res = await axios.get(`/users/${id}`);

    return res.data;
  } catch (e) {
    throw e;
  }
}