import React from 'react';
import { message } from 'antd';
import { getUserById } from '../../libs/api/users';
import UserDetails from '../../components/users/UserDetails';

class UserDetailsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      loading: false,
      error: null
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.fetchUserDetails(match.params.id);
    }
  }

  fetchUserDetails = id => {
    this.setState({ loading: true });

    getUserById(id)
      .then(user => this.setState({ user, loading: false }))
      .catch(error => {
        if (error.response && error.response.data) {
          if (error.response.data.detail) {
            this.setState({ error: { message: error.response.data.detail } });
          } else if (
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors.length
          ) {
            this.setState({
              error: { message: error.response.data.non_field_errors[0] }
            });
          }
        }
      });
  };

  render() {
    const { user, error, loading } = this.state;
    if (!user) return null;

    return (
      <div className="user">
        {error && message.error(error.message)}
        <UserDetails user={user} />
      </div>
    )
  }
}

export default UserDetailsPage;
